import React from 'react'
import Hero from '../Components/Hero';

const Home: React.FC = () => {

    return (
        <Hero></Hero>
    );
}

export default Home;